import React, { useEffect, useState } from "react";
import AuthenticatedTenant from "../../Layouts/AuthenticatedLayoutTenant";
import WorkPath from "../../UI-component/WorkPath";
import DynamicStepContent from "../../UI-component/DynamicStepContent";
import Privacy from "../_Partials/Privacy";
import General from "../_Partials/VotiveLamps/General";
import { ServiceDetailsAPI } from "../../Apis/ServiceDetailsAPI";
import Specific from "../_Partials/VotiveLamps/Specific";
import Summary from "../_Partials/VotiveLamps/Summary";
import PaymentPagePa from "../_Partials/PaymentPaPage";
import { useDispatch } from "react-redux";
import stepSlice from "../../context/StepsContext";


function LampadeVotive(props) {
  const dispatcher = useDispatch();

  const [header, setHeader] = useState("");
  const [user, setUser] = useState("");
  const [testData, setTestData] = useState({});
  const [bread, setBread] = useState({});

  //initialize localStorage conf once when component mounts ?
  useEffect(() => {
    //inizioalize first view first step
    if (localStorage.getItem("configuration")) {
      const config = JSON.parse(localStorage.getItem("configuration"));
      setHeader(config.data.current);
    }
    if (localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user"));
      setUser(user);
    }

    (async () => {
      try {
        const bread = await ServiceDetailsAPI.getBreadcrumbs("PCL");
        setBread(bread);
        const saved = await ServiceDetailsAPI.getSaved("pcl");

        if (!saved.success) {
          return;
        }

        // setTestData(saved.data);
        dispatcher(stepSlice.actions.onDraftData({ data: saved?.data, saved: false }));

      } catch (err) {
        console.log(err);
      }
    })()
  }, []);

  return (
    <AuthenticatedTenant auth={user} errors={props.errors} header={header}>
      <WorkPath workPathInfo={{
        base: ["Home", header?.siteUrl],
        medium: ["Servizi", header?.siteUrl + "servizi/"],
        category: [bread.category_name, bread.category_url],
        serviceName: [bread.service_name, bread.service_url],
        end: "Servizio digitale",
      }} />

      <div className="container" id="main-container">
        <div className="row justify-content-center" >
          <DynamicStepContent service={props.code}
            successTitle={"Pagamento canone per le lampade votive"}
            successDesc={`
            Inserisci le informazioni necessarie per pagare il canone per le lampade votive.<br>
            Potrai ricontrollare tutti i dati nel riepilogo, prima di inviare la richiesta.
              `}
            stepList={["INFORMATIVA SULLA PRIVACY", "DATI GENERALI", "DATI SPECIFICI", "RIEPILOGO", "PAGAMENTO"]} steps={[
              {
                component: Privacy, info: {
                  description: `Il ${JSON.parse(localStorage.getItem("configuration")).data.current.description}
                  esercita compiti e funzioni e gestisce servizi come indicato negli artt. 13/14 del Testo Unico Enti Locali (D.lgs. 267/2000 e ss integrazioni).
                  In questo contesto procede al trattamento dei dati personali dell’interessato necessari per fornire ogni tipo 
                  di servizio nel rispetto della normativa vigente (codice protezione dati personali e Regolamento europeo 16/679).
                  Per una completa informazione si invita a prendere conoscenza del contenuto della informativa resa ex art. 13
                  Regolamento europeo.`,
                  linkPrivacy: "",
                }
              },
              { component: General, info: { data: testData, code: props.code } },
              { component: Specific, info: { data: testData, code: props.code } },
              {
                component: Summary, info: {
                  data: testData, code: props.code,
                  headData: {
                    title: "Pagamento canone per le lampade votive",
                    description: `Inserisci le informazioni necessarie per pagare il canone per le lampade votive.<br>
                                  Potrai ricontrollare tutti i dati nel riepilogo, prima di inviare la richiesta.`
                  }
                }
              },
              // { component: PaymentPagePa, info: { data: testData, code: props.code } },


            ]} />

        </div>
      </div>

    </AuthenticatedTenant>
  );
}

export default LampadeVotive;