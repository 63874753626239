import React, { useEffect, useState } from "react";
import SideList from "../../../UI-component/SideList";
import ErrorAlert from "../../../UI-component/ErrorAlert";
import SummaryCard from "../../../UI-component/summary/SummaryCard";
import PopupTextField from "../../../UI-component/ui-el/PopupTextField";
import NextBtnForm from "../../../UI-component/NexBtnForm";
import { useDispatch, useSelector } from "react-redux";
import stepSlice from "../../../context/StepsContext";
import SuccessfullPopup from "../../../UI-component/SuccessfullPopup";
import CheckedFormBox from "../../../UI-component/CheckedFormBox";
import Loader from "../../../UI-component/Loader";

function Specific(props) {
  const dispatcher = useDispatch();

  const ctx = useSelector(state => state.stepSlice.data);
  const ctxApplicant = useSelector(state => state.stepSlice.data.richiedente);
  const ctxAcustic = useSelector(state => state.stepSlice.data.disabile);
  const ctxPrefs = useSelector(state => state.stepSlice.data.preferenze);

  const [loading, setLoading] = useState(false);
  const [specific, setSpecific] = useState(ctxPrefs.parcheggio);
  const [error, setError] = useState({
    tipo_di_invalidita: false,
    chiede: false,
    marca: false
  });

  // console.log("parking specific", specific, ctxPrefs)
  const [marca, setMarca] = useState({
    numero: specific?.marca_da_bollo?.numero,
    data: specific?.marca_da_bollo?.data
  });

  const onNext = () => {
    const obj = structuredClone(specific);

    obj.marca_da_bollo = marca;


    dispatcher(stepSlice.actions.dynamicSave({ id: "Preferenze", internalId: "parcheggio", data: obj }));

    props.next();
  }

  const errorChecker = () => {
    const err = {
      tipo_di_invalidita: specific.tipo_di_invalidita.length > 0 ? false : true,
      chiede: specific.chiede.length > 0 ? false : true,
      marca: (specific.tipo_di_invalidita.length < 0
        || !specific?.tipo_di_invalidita[0]?.toLowerCase().includes("temporanea")) ? false :
        marca.numero !== ""
          && marca.numero?.length === 14
          && marca.data !== "" ? false : true
    }

    setError(err);
  }

  useEffect(() => {
    errorChecker();
  }, [specific, marca])

  const update = (data) => {
    switch (data.id) {
      case "tipo_di_invalidita":
        setSpecific(prev => {
          const updatedSpecific = { ...prev, tipo_di_invalidita: data.data };
          // Se l'invalidità è "Permanente", resetta i campi "Numero" e "Data" della "Marca da bollo"
          if (data.data[0]?.toLowerCase() === "permanente") {
            setMarca({ numero: "", data: "" });
          }
          return updatedSpecific;
        });

        break;
      case "chiede":
        setSpecific(prev => {
          return { ...prev, chiede: data.data }
        });
        break;
      default:
        break;
    }
  }

  const onChange = (e) => {
    setSpecific(prev => {
      return { ...prev, vecchio_contrassegno: e.target.value }
    })
  }

  const save = async () => {
    const newPref = { ...ctxPrefs };

    const updatedSpecific = { ...specific, marca_da_bollo: marca };
    newPref.parcheggio = updatedSpecific;

    const data = {
      richiedente: ctxApplicant?.[0]?.["0"] ? [{ ...ctxApplicant?.[0]?.["0"], selected: true }] : (ctxApplicant?.length > 0 ? [{ ...ctxApplicant[0], selected: true }] : []),
      disabile: ctxAcustic?.[0]?.["0"] ? [{ ...ctxAcustic?.[0]?.["0"], selected: true }] : ctxAcustic?.length > 0 ? [{ ...ctxAcustic[0], selected: true }] : [],
      // richiedente: ctxApplicant?.length > 0 ? [{ ...ctxApplicant[0], selected: true }] : [],
      // disabile: ctxAcustic?.length > 0 ? [{ ...ctxAcustic[0], selected: true }] : [],
      savedDis: ctx?.savedDis,
      preferenze: newPref
    }

    // console.log("data", data);
    setLoading(true);

    await props.save(data);

    dispatcher(stepSlice.actions
      .dynamicSave({ id: "Preferenze", internalId: "parcheggio", data: updatedSpecific }));

    setLoading(false);
  }

  /* const save = async () => {
    const newPref = { ...ctxPrefs };

    newPref.parcheggio = specific;
    newPref.marca_da_bollo = marca;

    const data = {
      richiedente: ctxApplicant?.length > 0 ? [{ ...ctxApplicant[0], selected: true }] : [],
      disabile: ctxAcustic?.length > 0 ? [{ ...ctxAcustic[0], selected: true }] : [],
      savedDis: ctx?.savedDis,
      preferenze: newPref
    }

    setLoading(true);

    await props.save(data);

    dispatcher(stepSlice.actions
      .dynamicSave({ id: "Preferenze", internalId: "parcheggio", data: specific }));

    setLoading(false);
  } */

  const [dateError, setDateError] = useState(false);

  const onMarcaChange = (e) => {
    if (e.target.id === "numero") {
      setMarca(prev => {
        return { ...prev, numero: e.target.value }
      })
    } else {
      const today = new Date().toISOString().split('T')[0];
      if (e.target.value > today) {
        setDateError(true);  // Imposta l'errore della data
        setMarca(prev => ({
          ...prev,
          data: today  // Imposta la data odierna se è futura
        }));
      } else {
        setDateError(false);
        setMarca(prev => {
          return { ...prev, data: e.target.value }
        });
      }
    }
  }


  return (
    <div className="row">
      <SideList
        infoList={[
          { element: "Tipo di invalidità", id: "#report-author" },
          { element: "Chiede", id: "#report-author" },
          { element: "Vecchio contrassegno", id: "#report-author" },
        ]} />

      <div className="col-12 col-lg-8 offset-lg-1">
        <div className="steppers-content" aria-live="polite">
          <div className="it-page-sections-container">
            <ErrorAlert errors={[{ name: "Tipo di invalidità", id: "#isee-info" }]} err={error.tipo_di_invalidita} />
            <ErrorAlert errors={[{ name: "Chiede", id: "#isee-info" }]} err={error.chiede} />
            <ErrorAlert errors={[{ name: "Marca da bollo", id: "#isee-info" }]} err={error?.marca} />

            <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-55%,-50%)", zIndex: "10000" }}>
              <Loader loading={loading} />
            </div>
            <CheckedFormBox
              type="radio"
              title={["Parcheggio", "Tipo di invalidità"]}
              description="In caso di invalidità “temporanea” non è previsto il rinnovo e, ove occorre, deve essere richiesto un nuovo rilascio allegando la certificazione dell’ufficio medico legale dell’ASL competente. Alla consegna del contrassegno portare ulteriori marca da bollo da 16,00 euro"
              choices={[
                { value: "Permanente" },
                { value: "Temporanea, inferiore ad anni 5" }
              ]}
              defaultValue={specific?.tipo_di_invalidita}
              update={update}
            />

            <CheckedFormBox
              type="radio"
              title={["Parcheggio", "Chiede"]}
              description="(ai sensi del D.P.R. n. 151/2012 e dell'art. 381, comma 3 del D.P.R. n. 495 del 16/12/92, così come modificato dal D.P.R. n. 610 del 16/9/96) In caso di “rilascio” è necessario allegare il certificato, rilasciato dall'ufficio medico-legale dell'ASL competente per territorio, attestante l’effettiva ridotta capacità di deambulazione. In caso di “rinnovo” è necessario allegare il certificato, rilasciato dal medico curante, attestante il persistere delle condizioni di ridotta capacità di deambulazione che hanno dato luogo al rilascio. In caso di “duplicato” è necessario allegare: in caso di smarrimento o furto, la relativa denuncia; in caso di deterioramento, riconsegnare il tagliando deteriorato. È sempre necessario allegare 2 fototessere recenti"
              choices={[
                { value: "Rilascio del contrassegno" },
                { value: "Rinnovo del contrassegno" },
                { value: "Duplicato del contrassegno" }
              ]}
              defaultValue={specific?.chiede}
              update={update}
            />

            <SummaryCard
              type="Vecchio contrassegno"
              description="In caso di rinnovo portare il vecchio contrassegno al momento del ritiro del nuovo"
            >
              <PopupTextField
                defaultValue={specific?.vecchio_contrassegno}
                fieldType="text"
                id="vecchio_contrassegno"
                fieldTitle="Vecchio contrassegno"
                description=" "
                onChange={onChange}
              />
            </SummaryCard>

            {
              specific?.tipo_di_invalidita[0]?.toLowerCase().includes("temporanea") &&
              <SummaryCard
                type="Marca da bollo"
                description=" "
              >
                <PopupTextField
                  defaultValue={marca.numero}
                  fieldType="text"
                  id="numero"
                  fieldTitle="Numero"
                  description="Aggiungi un numero alla tua richiesta"
                  onChange={onMarcaChange}
                  regex="^[0-9]{14}$"
                  max="14"
                  min="14"
                />

                <PopupTextField
                  defaultValue={marca?.data}
                  fieldType="date"
                  id="data"
                  fieldTitle="Data"
                  description="Aggiungi una data alla tua richiesta"
                  onChange={onMarcaChange}
                  max={new Date().toISOString().split('T')[0]}
                  style={{ borderColor: dateError ? 'red' : 'initial' }}
                />
                {dateError && (
                  <div style={{ color: 'red', marginTop: '-30px', fontSize: '14px' }}>
                    Inserisci una data valida
                  </div>
                )}
              </SummaryCard>
            }
          </div>
        </div>
        <NextBtnForm
          next={onNext}
          saveRequest={save}
          back={props.back}
          disabled={error.tipo_di_invalidita || error.chiede || error.marca || dateError} />
        {
          props.saved &&
          <SuccessfullPopup />
        }

        {
          props.fail &&
          <SuccessfullPopup isError={true} />
        }
      </div>
    </div>
  )
}

export default Specific;