import React, { useEffect } from "react";


function CustomerCardHeader(props) {
    const { disabled, defaultValue } = props;

    const setTypology = (e) => {
        props.setType(e.target.options[e.target.selectedIndex].text);
    }

    useEffect(() => {
        if (defaultValue) props.setType(defaultValue);
    }, [])

    return (
        <div className="card-header border-0 p-0 mb-lg-30 m-0">
            <div className="d-flex">
                <h2 className="title-xxlarge mb-1">{props.title}</h2>
            </div>
            <p className="subtitle-small mb-0">{props.description}</p>
            <br></br>
            {
                props.type &&
                <div className="select-wrapper mt-40 mb-3 mb-lg-30 bg-transparent p-0">
                    <label htmlFor="taxpayer-type">Tipologia*</label>
                    <select
                        onChange={!disabled ? setTypology : () => { }}
                        disabled={disabled}
                        id="taxpayer-type"
                        className={`bg-transparent ${disabled ? "disabled" : ""}`}
                        required
                        defaultValue={defaultValue || 'default'}>
                        <option value="default" disabled>Seleziona opzione</option>
                        <option value="Persona fisica">Persona fisica</option>
                        <option value="Persona giuridica">Persona giuridica</option>
                    </select>
                </div>
            }
        </div>
    )
}


export default CustomerCardHeader;