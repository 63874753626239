import { api, apiAuth } from "./Configs/AxiosConfigs"

export const PaymentApi = {

  getPayments: async function () {
    const response = await api.request({
      url: "/payments/",
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      },
    })
    return response.data;
  },

  createDebit: async function ({ id, causal, total, paymentCode, code }) {
    const body = {
      id: id,
      causal: causal,
      total: total,
      paymentCode: paymentCode,
      code: code
    }

    const response = await api.request({
      url: "/create-debit/",
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      },
      data: body
    });
    return response.data;
  },

  getBullettin: async function (obj) {
    const response = await api.request({
      url: "/payments/get-bollettino",
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      },
      data: obj
    });
    return response.data;
  },

  downloadCheck: async function (iuv) {
    const body = {
      iuv: iuv
    }

    const response = await api.request({
      url: "/stampa-avviso",
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      },
      data: body
    });
    return response.data;
  },

  getInvoiceInfoById: async function (code) {
    const body = {
      code: code,
    }

    const response = await api.request({
      url: "/get-invoice-data",
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      },
      data: body
    });
    return response.data;
  },

  updatePaymentState: async function (body) {

    const response = await api.request({
      url: "/payments/check-status-iuv",
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      },
      data: body
    });
    return response.data;
  },

  checkPaymentStatus: async function (body) {

    const response = await api.request({
      url: "/payments/check-payment-status",
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      },
      data: body
    });
    return response.data;
  },

  recreateUrl: async function (body) {
    const response = await api.request({
      url: "/payments/get-url-pagopa",
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      },
      data: body
    });
    return response.data;
  },

  getFinesInfo: async function (code) {
    const body = {
      iuv: code,
    }

    const response = await api.request({
      url: "/get-request-data",
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      },
      data: body
    });
    return response.data;
  },


}
