import React from "react";
import "../global.css"

function Loader(props) {
    return (
        <>
            {
                props.loading &&
                <div className="lds-dual-ring"></div>
            }
        </>
    )
}

export default Loader;