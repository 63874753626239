import React, { useCallback, useEffect, useState } from "react";
import SideList from "../../UI-component/SideList";
import { useDispatch, useSelector } from "react-redux";
import { dynamicRenderer, titleNormalizer } from "../../util/Dynamics";
import ErrorAlert from "../../UI-component/ErrorAlert";
import NextBtnForm from "../../UI-component/NexBtnForm";
import stepSlice from "../../context/StepsContext";
import SuccessfullPopup from "../../UI-component/SuccessfullPopup";
import SummaryCard from "../../UI-component/summary/SummaryCard";
import PopupTextField from "../../UI-component/ui-el/PopupTextField";
import Loader from "../../UI-component/Loader";

export default function SuoloServiziSpecifici(props) {
  const dispatcher = useDispatch();

  const preferencesCtx = useSelector(state => {
    return state.stepSlice.data.preferenze[props.info?.service_name]
  });
  const ctx = useSelector(state => state.stepSlice.data);

  console.log("/////////////// suolo", props.info.data.components, preferencesCtx)

  const indexer = [];

  const [error, setError] = useState({
    school: false,
    marca: false
  });

  const [testData, setTestData] = useState(preferencesCtx);
  const [marca, setMarca] = useState({
    numero: preferencesCtx?.marca_da_bollo?.numero,
    data: preferencesCtx?.marca_da_bollo?.data
  });
  const [loading, setLoading] = useState(false);
  const sideList = props.info.data.side_list;

  const errorChecker = () => {
    if (testData?.scuola !== "" &&
      testData?.classe !== "") {
      setError(prev => {
        return { ...prev, school: false };
      });
    } else {
      setError(prev => {
        return { ...prev, school: true };
      })
    }

    if (marca.numero !== "" && marca.numero?.length === 14 && marca.data !== "") {
      setError(prev => {
        return { ...prev, marca: false }
      })
    } else {
      setError(prev => {
        return { ...prev, marca: true }
      })
    }
  }

  useEffect(() => {
    errorChecker();
  }, [testData, marca])

  const update = (data) => {
    setTestData(prev => {
      const tmp = structuredClone(prev);

      if (data.parentId) {
        tmp[data.parentId][data.id.normalize('NFD').replace(/[\u0300-\u036f]/g, '')] = data.data;
      } else {
        const normalizedID = data.id.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        tmp[normalizedID] = data.data;
      }

      return { ...prev, ...tmp }
    });
  }

  const onNext = async () => {
    const obj = structuredClone(testData);
    obj.marca_da_bollo = marca;

    dispatcher(stepSlice.actions.dynamicSave({ id: "Preferenze", internalId: props.info?.service_name, data: obj }));

    setLoading(true);

    await props.next();

    setLoading(true);
  }

  const rendering = () => {
    return props.info.data.components.map((itm, idx) => {
      let id = null;
      let parentId = null;

      if (itm.title[1].toLowerCase().includes("marca")) {
        return <div key={idx}></div>
      }

      if (Array.isArray(itm.title)) {
        parentId = itm.title[0].replaceAll(" ", "_").toLowerCase();
        id = itm.title[1].replaceAll(" ", "_").toLowerCase();
      } else
        id = itm.title.replaceAll(" ", "_").toLowerCase();


      if (itm.type === "MultiText") {
        let multiValues = [];

        for (let i of itm.child_title) {
          if (parentId !== null) {
            multiValues.push(
              preferencesCtx[id] ? preferencesCtx[id][titleNormalizer(i)] : "");
          }
          else
            multiValues.push(
              preferencesCtx[id][titleNormalizer(i)] ? preferencesCtx[id][titleNormalizer(i)] : "");
        }

        return dynamicRenderer(itm, "", indexer, "Preferenze", null, multiValues, update);
      } else {
        if (Array.isArray(itm.title)) {
          if (id === "scuola") {
            return dynamicRenderer(
              itm,
              preferencesCtx[id],
              indexer,
              "Preferenze",
              preferencesCtx.classe,
              null,
              update);
          }

          return dynamicRenderer(
            itm,
            preferencesCtx[id.normalize("NFD").replace(/[\u0300-\u036f]/g, "")],
            indexer, "Preferenze",
            null,
            null,
            update);
        }
        else {
          return dynamicRenderer(
            itm,
            preferencesCtx[id],
            indexer,
            "Preferenze",
            null,
            update);
        }
      }

      // return <div key={idx}></div>;
    })
  }

  const save = async () => {
    const obj = structuredClone(testData);
    obj.marca_da_bollo = marca;

    dispatcher(stepSlice.actions.dynamicSave({ id: "Preferenze", internalId: props.info?.service_name, data: obj }));

    const data = structuredClone(ctx);

    data.preferenze[props.info?.service_name] = obj;

    setLoading(true);

    await props.save(data);

    setLoading(false);
  }

  const [dateError, setDateError] = useState(false);

  const onMarcaChange = (e) => {
    if (e.target.id === "numero") {
      setMarca(prev => {
        return { ...prev, numero: e.target.value }
      })
    } else {
      const today = new Date().toISOString().split('T')[0];
      if (e.target.value > today) {
        setDateError(true);  // Imposta l'errore della data
        setMarca(prev => ({
          ...prev,
          data: today  // Imposta la data odierna se è futura
        }));
      } else {
        setDateError(false);
        setMarca(prev => {
          return { ...prev, data: e.target.value }
        });
      }
    }
  }

  // QUELLO DELLA RICERCA E 1
  // TOGLIERE IL TITOLO PAGAMENTO

  return (
    <div className="row">
      <SideList
        infoList={sideList}
      />

      <div className="col-12 col-lg-8 offset-lg-1">
        <div className="steppers-content" aria-live="polite">
          <div className="it-page-sections-container">
            <div style={{ padding: "20px" }}>
              {
                props.info.data?.explanation &&
                <p>
                  <strong style={{ color: "#a66300" }}>Attenzione: </strong>{props.info.data?.explanation.description}: &nbsp;
                  <a href={props.info.data?.explanation.link} target="__blank">Guida alla compilazione dei dati specifici</a>
                </p>
              }
            </div>
            {/* TODO: on config ready check for the error so that only one file is used */}
            {
              <ErrorAlert errors={[{ name: "Scuola", id: "#isee-info" }]} err={error?.school} />
            }

            {
              props.info.code === "PSP" &&
              <ErrorAlert errors={[{ name: "Marca da bollo", id: "#isee-info" }]} err={error?.marca} />
            }

            {
              useCallback(rendering(), [])
            }

            {
              props.info.code === "PSP" &&
              <SummaryCard
                type="Marca da bollo*"
                description=" "
              >
                <PopupTextField
                  defaultValue={marca.numero}
                  fieldType="text"
                  id="numero"
                  fieldTitle="Numero"
                  description="Aggiungi un numero alla tua richiesta"
                  onChange={onMarcaChange}
                  regex="^[0-9]{14}$"
                  max="14"
                  min="14"
                />

<PopupTextField
                  defaultValue={marca?.data}
                  fieldType="date"
                  id="data"
                  fieldTitle="Data"
                  description="Aggiungi una data alla tua richiesta"
                  onChange={onMarcaChange}
                  max={new Date().toISOString().split('T')[0]}
                  style={{ borderColor: dateError ? 'red' : 'initial' }}
                />
                {dateError && (
                  <div style={{ color: 'red', marginTop: '-30px', fontSize: '14px' }}>
                    Inserisci una data valida
                  </div>
                )}

              </SummaryCard>
            }
          </div>
        </div>
        <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-55%,-50%)", zIndex: "10000" }}>
          <Loader loading={loading} />
        </div>
        {
          props.info.code === "PSP" ?
            <NextBtnForm
              saveRequest={save}
              next={onNext}
              back={props.back}
              disabled={error.marca} /> :
            <NextBtnForm
              saveRequest={save}
              next={onNext}
              back={props.back}
              disabled={error.school} />
        }


        {
          props.saved &&
          <SuccessfullPopup />
        }

        {
          props.fail &&
          <SuccessfullPopup isError={true} />
        }
      </div>
    </div>
  );
}
















