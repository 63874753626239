import React, { useEffect, useState } from "react";
import SideList from "../../../UI-component/SideList";
import ErrorAlert from "../../../UI-component/ErrorAlert";
import NextBtnForm from "../../../UI-component/NexBtnForm";
import { useDispatch, useSelector } from "react-redux";
import stepSlice from "../../../context/StepsContext";
import SuccessfullPopup from "../../../UI-component/SuccessfullPopup";
import Loader from "../../../UI-component/Loader";
import SelectionFormBox from "../../../UI-component/SelectionFormBox";

function Specific(props) {
  const dispatcher = useDispatch();

  const ctxApplicant = useSelector(state => state.stepSlice.data.bonus.richiedente);
  const ctxIsee = useSelector(state => state.stepSlice.data.bonus.isee);
  const ctxCredits = useSelector(state => state.stepSlice.data.bonus.credito);
  const ctxPrefs = useSelector(state => state.stepSlice.data.preferenze);

  const [loading, setLoading] = useState(false);
  const [specific, setSpecific] = useState(ctxPrefs?.bonus);
  const [error, setError] = useState({
    tipologia: false,
  });

  const onNext = () => {
    dispatcher(stepSlice.actions.dynamicSave({ id: "Preferenze", internalId: "bonus", data: specific }));

    props.next();
  }

  const errorChecker = () => {
    // const err = {
    //   tipo_di_invalidita: specific.tipo_di_invalidita.length > 0 ? false : true,
    //   chiede: specific.chiede.length > 0 ? false : true
    // }

    // setError(err);
  }

  const update = (data) => {
    switch (data.id) {
      case "tipologia":
        setSpecific(prev => {
          return { ...prev, tipologia: data.data }
        });
        break;
      default:
        break;
    }
  }

  const save = async () => {
    let data = {
      richiedente: ctxApplicant?.length > 0 ? [{ ...ctxApplicant[0], selected: true }] : [],
      credito: ctxCredits,
      isee: ctxIsee,
      preferenze: ctxPrefs
    }

    let obj = structuredClone(data);

    obj.preferenze.bonus = specific;

    data = obj;
    setLoading(true);

    await props.save(data);

    dispatcher(stepSlice.actions
      .dynamicSave({ id: "Bonus", data: data }));

    dispatcher(stepSlice.actions
      .dynamicSave({ id: "Preferenze", internalId: "bonus", data: specific }));

    setLoading(false);
  }

  useEffect(() => {
    errorChecker();
  }, [specific])

  return (
    <div className="row">
      <SideList
        infoList={[
          { element: "Tipologia", id: "#report-author" },
        ]} />

      <div className="col-12 col-lg-8 offset-lg-1">
        <div className="steppers-content" aria-live="polite">
          <div className="it-page-sections-container">
            <ErrorAlert errors={[{ name: "Tipologia", id: "#isee-info" }]} err={error.tipo_di_invalidita} />

            <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-55%,-50%)", zIndex: "10000" }}>
              <Loader loading={loading} />
            </div>

            <SelectionFormBox
              showTitle={`Tipologia*`}
              title={"tipologia"}
              description={" "}
              choices={[{
                id: "1",
                value: "Nessun bonus attivo al momento"
              }]}
              defaultValue={specific?.tipologia}
              classDefaultValue={""}
              oKey={""}
              update={update}
              disabled={"  " === "" ? "disabled" : ""} />

          </div>
        </div>
        <NextBtnForm
          next={onNext}
          saveRequest={save}
          back={props.back}
          disabled={false} />
        {
          props.saved &&
          <SuccessfullPopup />
        }

        {
          props.fail &&
          <SuccessfullPopup isError={true} />
        }
      </div>
    </div>
  )
}

export default Specific;