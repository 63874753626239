import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SideList from "../../../UI-component/SideList";
import ErrorAlert from "../../../UI-component/ErrorAlert";
import CustomerFormBox from "../../../UI-component/registryForms/CustomerFormBox";
import NextBtnForm from "../../../UI-component/NexBtnForm";
import stepSlice from "../../../context/StepsContext";
import SuccessfullPopup from "../../../UI-component/SuccessfullPopup";
import Loader from "../../../UI-component/Loader";

function General(props) {
  const dispatcher = useDispatch();
  const ctxApplicant = useSelector(state => state.stepSlice.data.richiedente);
  const isStopped = useSelector(state => state.stepSlice.data.stopped);

  const [applicant, setApplicant] = useState(/*ctxApplicant.length <= 0 ? props.info.data?.richiedente : */ctxApplicant);

  const [error, setError] = useState({
    applicant: false
  });

  const [loading, setLoading] = useState(false);

  const errorChecker = () => {
    let errors = {
      applicant: false
    }

    if (!applicant || applicant?.length <= 0)
      errors.applicant = true;
    else
      error.applicant = false;

    setError(errors);
  }

  useEffect(() => {
    errorChecker();
  }, [applicant])

  const update = (data) => {
    if (data.id.toLowerCase() === "richiedente") {
      if (typeof data === "object") {
        if (data.removing) {
          setApplicant(undefined);
          return;
        }

        setApplicant(data.data);
      }
    }
  }

  const onNext = () => {
    dispatcher(stepSlice.actions
      .dynamicSave({ id: "Richiedente", data: { ...applicant[0], selected: true } }));

    props.next();
  }

  const save = async () => {
    const data = {
      richiedente: applicant?.length > 0 ? [{ ...applicant[0], selected: true }] : [],
    }

    setLoading(true);

    await props.save(data);

    dispatcher(stepSlice.actions
      .dynamicSave({ id: "Richiedente", data: { ...applicant[0], selected: true } }));

    setLoading(false);
  }

  return (
    <div className="row">
      <SideList
        infoList={[
          { element: "Richiedente", id: "#report-author" },
        ]} />

      <div className="col-12 col-lg-8 offset-lg-1">
        <div className="steppers-content" aria-live="polite">
          <div className="it-page-sections-container">

            {
              <ErrorAlert errors={[{ name: "Richiedente", id: "#isee-info" }]} err={error.applicant || isStopped} />
            }

            <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-55%,-50%)", zIndex: "10000" }}>
              <Loader loading={loading} />
            </div>

            <CustomerFormBox id="richiedente"
              cards={applicant?.length > 0 ? [applicant] : []}
              type={false}
              description="Informazione su di te"
              title="Richiedente"
              service={props.info.code}
              update={update}
              prefData={props.info.data?.richiedente}
            />
          </div>
        </div>
        <NextBtnForm
          next={onNext}
          saveRequest={save}
          back={props.back}
          disabled={error.applicant || isStopped} />
        {
          props.saved &&
          <SuccessfullPopup />
        }

        {
          props.fail &&
          <SuccessfullPopup isError={true} />
        }
      </div>
    </div>
  )
}


export default General;