import React, { useCallback, useEffect, useRef, useState } from "react";
import "../../global.css";


function PopupTextField(props) {
  const inRef = useRef(null);
  const [valid, setValid] = useState(true);
  const isFile = props.fieldType === "file";
  const isDate = props.fieldType === "date";

  const blur = (e) => {
    if (props?.onBlur)
      props.onBlur(e);

    isValid();
  }

  const isValid = useCallback(() => {
    try {
      if (props?.regex || props.error) {
        const reg = new RegExp(props.regex || "[a-zA-Z0-9]");
        //console.log('Regex utilizzata:', reg); // Log per vedere la regex
        if (reg.test(inRef.current.value)) {
          //console.log('Valore valido:', inRef.current.value); // Log per vedere il valore testato
          setValid(true);
        } else {
          //console.log('Valore non valido:', inRef.current.value); // Log per il valore non valido
          setValid(false);
        }
      }
    } catch (error) {
      console.error('Errore nella creazione della regex:', error.message);
      setValid(false);
    }
  }, [props.error, props.regex]);
  

  

  useEffect(() => {
    if (props?.straightErr !== undefined) {
      setValid(!props.straightErr);
      return;
    }

    if (props.error)
      isValid();
  }, [isValid, props?.error, props?.straightErr])

  const removeFileMod = (e) => {
    document.getElementById(e.target.id.split("x-")[1]).value = "";
    const x = { target: document.getElementById(e.target.id.split("x-")[1]) };

    props.onChange(x);

  }

  useEffect(() => {
    isValid();
    if(props.defaultValue !== inRef?.current?.value && props.defaultValue && props?.fieldType === "text"){
      inRef.current.value = props.defaultValue;
    }
    // console.log("props popup text", props.defaultValue)
  }, [props.defaultValue])

  // console.log("props", props);

  const getDateConstraints = () => {
    if (isDate) {
      return {
        min: props.min || "1900-01-01",
        max: props.max || new Date().toISOString().split('T')[0]
      };
    }
    return {
      min: props.fieldType === "number" ? (props.min || 0) : undefined,
      max: props.fieldType === "number" ? props.max : undefined
    };
  };

  return (
    <div className="form-group cmp-input top-move">
      <label
        className={`cmp-input__label ${props.defaultValue ? "active" : ""} ${props.disabled ? "disabled" : ""} ${(props.fieldType === "date" || props.fieldType === "file") && "up-lbl"}`}
        htmlFor={props.id}>{props.fieldTitle}
      </label>
      {
        isFile && props?.defaultValue !== "" ?
          <>
            <div style={{ padding: "50px 0 0 0", borderBottom: "solid 2px " }}>
              <label
                htmlFor={props.id}
                style={{ cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center" }}>

                <div >
                  <p className="change-file-span">
                    <span
                      style={{
                        borderRadius: ".3rem",
                        color: "var(--primary)",
                        fontSize: "14px",
                        padding: "0 .2rem 0 .2rem",
                        marginRight: ".5rem",
                        fontWeight: "bold"
                      }}
                    >
                      Modifica
                    </span>

                    {inRef?.current?.files[0]?.name.substring(0, 33) ?? props?.defaultValue.substring(0, 33)}
                  </p>
                </div>
                <input
                  onWheel={(e) => { e.target.blur() }}
                  style={{ fontWeight: "600", width: "100%" }}
                  onBlur={blur}
                  onChange={props.onChange}
                  type={props.fieldType}
                  ref={inRef}
                  className={`form-control ${props.disabled ? "disabled" : ""}  ${valid ? "" : "in-err"} ${isFile && props?.defaultValue !== "" ? "transparent" : ""} toCheck`}
                  id={props.id}
                  name="vehicle-use"
                  required
                  data-dashlane-rid="f6746310e853864c"
                  data-form-type="other"
                  defaultValue={!isFile ? props?.defaultValue : ""}
                  itr={props.itr}
                />
              </label>
            </div >
            <input
              onWheel={(e) => { e.target.blur() }}
              style={{
                marginTop: "-80px",
                background: "transparent",
                border: "solid 1px red",
                borderRadius: "5px",
                fontSize: "11px",
                color: "red",
                float: "right",
                width: "20px",
                height: "20px",
                fontWeight: "bold"

              }}
              type="button" value="X"
              id={`x-${props.id}`}
              onClick={removeFileMod} />
          </>
          :
          <input
            maxLength={props?.max}
            {...getDateConstraints()}
            onWheel={(e) => { e.target.blur() }}
            style={{ fontWeight: "600", border: "none", borderBottom: "solid 1.5px grey", boxShadow: "none", padding: "5px" }}
            onBlur={blur}
            onChange={props.onChange}
            type={props.fieldType}
            ref={inRef}
            className={`${props.disabled ? "disabled" : ""}  ${valid ? "" : "in-err"} full-btn-file`}
            id={props.id}
            name="vehicle-use"
            required
            data-dashlane-rid="f6746310e853864c"
            data-form-type="other"
            defaultValue={!isFile ? props?.defaultValue : ""}
            itr={props.itr}
           
          />
      }
      <div className="d-flex">
        <span className="form-text cmp-input__text">{props.description ? props.description : "Inserire l’informazione per proseguire con la richiesta"}</span>
      </div>
    </div >
  )

}

export default PopupTextField;
