import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SideList from "../../../UI-component/SideList";
import ErrorAlert from "../../../UI-component/ErrorAlert";
import CustomerFormBox from "../../../UI-component/registryForms/CustomerFormBox";
import NextBtnForm from "../../../UI-component/NexBtnForm";
import stepSlice from "../../../context/StepsContext";
import SuccessfullPopup from "../../../UI-component/SuccessfullPopup";
import Loader from "../../../UI-component/Loader";
import SummaryCard from "../../../UI-component/summary/SummaryCard";
import PopupTextField from "../../../UI-component/ui-el/PopupTextField";
import { onFileSet2 } from "../../../util/Dynamics";
import IseeFormBox from "../../../UI-component/registryForms/IseeFormBox";

function General(props) {
  const dispatcher = useDispatch();
  const ctx = useSelector(state => state.stepSlice.data.bonus);
  const ctxPrefs = useSelector(state => state.stepSlice.data.preferenze);

  const ctxIsee = useSelector(state => state.stepSlice.data.bonus.isee);
  const iseeRequired = useSelector(() => true);
  const isStopped = useSelector(state => state.stepSlice.data.stopped);

  const [applicant, setApplicant] = useState(ctx?.richiedente);
  const [isee, setIsee] = useState(ctxIsee);
  const [credit, setCredit] = useState(Object.keys(ctx?.credito).length <= 0 ? {
    intestatario: props.info?.data?.credito?.intestatario || "",
    banca: props.info?.data?.credito?.banca || "",
    filiale: props.info?.data?.credito?.filiale || "",
    indirizzo: props.info?.data?.credito?.indirizzo || "",
    codice_iban: props.info?.data?.credito?.codice_iban || "",
    iban: props.info?.data?.credito?.iban || "",
    base64_iban: props.info?.data?.credito?.base64_iban || "",
  } : ctx?.credito);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    applicant: false,
    credit: false,
    ibanErr: false,
    isee: false
  });

  const errorChecker = () => {
    let err = false;
    let ibanErr = false;
    /* let iseeErr = (Array.isArray(isee) && isee?.length > 0) || (isee && Object.keys(isee).length > 0) ? false : true; */

    let iseeErr = !isee || isee.length === 0 || isee.every(item => item === null);

    for (let i of Object.keys(credit)) {
      if (credit[i] === "") {
        err = true
        ibanErr = i === "codice_iban" ? true : false;
        break;
      }

      if (i === "codice_iban") {
        const regex = RegExp("^IT\\d{2}[A-Za-z]\\d{10}[0-9A-Za-z]{12}$");

        if (!regex.test(credit[i])) {
          ibanErr = true;
          err = true;
          break;
        }
        else {
          ibanErr = false;
        }
      }
    }

    setError(prev => {
      const appl = !applicant || applicant?.length <= 0 ? true : false;

      return { ...prev, applicant: appl, credit: err, ibanErr: ibanErr, isee: iseeErr }
    });
  }

  const update = (data) => {
    if (typeof data === "object") {
      if (data.id.toLowerCase() === "richiedente") {
        if (data.removing) {
          setApplicant(undefined);
          return;
        }

        setApplicant(data.data);
      }

      if (data.id.toLowerCase() === "isee") {
        setIsee(data.data)
      }
    }
  }

  const onNext = () => {
    const data = {
      richiedente: applicant?.length > 0 ? [{ ...applicant[0], selected: true }] : [],
      credito: credit,
      isee: isee
    }

    dispatcher(stepSlice.actions
      .dynamicSave({ id: "Bonus", data: data }));

    props.next();
  }

  const save = async () => {
    const data = {
      richiedente: applicant?.length > 0 ? [{ ...applicant[0], selected: true }] : [],
      credito: credit,
      isee: isee,
      preferenze: ctxPrefs
    }

    setLoading(true);

    await props.save(data);

    dispatcher(stepSlice.actions
      .dynamicSave({ id: "Bonus", data: data }));

    // dispatcher(stepSlice.actions.dynamicSave({ id: "Preferenze", internalId: "bonus", data: specific }));

    setLoading(false);
  }

  const onChange = (e) => {
    setCredit((prev) => {
      const obj = structuredClone(prev);
      obj[e.target.id] = e.target.value;
      prev = obj;
      return { ...prev }
    })
  }

  useEffect(() => {
    errorChecker();
  }, [applicant, credit, isee])


  return (
    <div className="row">
      <SideList
        infoList={[
          { element: "Richiedente", id: "#report-author" },
          { element: "ISEE", id: "#report-author" },
          { element: "Dati per l'accredito", id: "#report-author" },

        ]} />

      <div className="col-12 col-lg-8 offset-lg-1">
        <div className="steppers-content" aria-live="polite">
          <div className="it-page-sections-container">
            {
              <ErrorAlert errors={[{ name: "Richiedente", id: "#isee-info" }]} err={error.applicant || isStopped} />
            }

            {
              <ErrorAlert errors={[{ name: "ISEE", id: "#isee-info" }]} err={error.isee} />
            }

            {
              <ErrorAlert errors={[{ name: "Dati per l'accredito", id: "#isee-info" }]} err={error.credit} />
            }

            <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-55%,-50%)", zIndex: "10000" }}>
              <Loader loading={loading} />
            </div>
            <CustomerFormBox id="richiedente"
              cards={applicant?.length > 0 ? [applicant] : []}
              type={false}
              description="Informazione su di te"
              title="Richiedente"
              service={props.info.code}
              update={update}
              prefData={props.info.data?.richiedente}
            />

            <IseeFormBox
              id={"isee"}
              cards={isee ? Array.isArray(isee) ? isee : [isee] : []}
              optionalTitle={false}
              update={update}
              placeholderData={props.info?.data?.isee}
              required={iseeRequired}
            />

            <SummaryCard
              type="Dati per l'accredito*"
              description={"Inserisci i dati per l'accredito"}>
              <PopupTextField
                defaultValue={credit?.intestatario}
                fieldType="text"
                id="intestatario"
                fieldTitle="Intestatario*"
                description="Inserisci l'intestatario"
                max="18"
                onChange={onChange}
              />
              <PopupTextField
                defaultValue={credit?.banca}
                fieldType="text"
                id="banca"
                fieldTitle="Banca*"
                description="Inserisci il nome della banca"
                max="18"
                onChange={onChange}
              />
              <PopupTextField
                defaultValue={credit?.filiale}
                fieldType="text"
                id="filiale"
                fieldTitle="Filiale*"
                description="Inserisci la filiale"
                max="18"
                onChange={onChange}
              />
              <PopupTextField
                defaultValue={credit?.indirizzo}
                fieldType="text"
                id="indirizzo"
                fieldTitle="Indirizzo*"
                description="Inserisci l'indirizzo"
                max="18"
                onChange={onChange}
              />
              <PopupTextField
                defaultValue={credit?.codice_iban}
                fieldType="text"
                id="codice_iban"
                fieldTitle="Codice IBAN*"
                description="Inserisci il codice IBAN"
                max="34"
                min="34"
                straightErr={error.ibanErr}
                onChange={onChange}
              />

              <PopupTextField
                onChange={(e) => { onFileSet2(e, setCredit) }}
                fieldType="file"
                id="iban"
                defaultValue={credit?.iban || ""}
                fieldTitle="Documento IBAN*"
                description="Allegare il documento IBAN" />

            </SummaryCard>

          </div>
        </div>
        <NextBtnForm
          next={onNext}
          back={props.back}
          noSave={false}
          saveRequest={save}
          disabled={error.applicant || error.credit || error.isee || isStopped} />

        {
          props.saved &&
          <SuccessfullPopup />
        }

        {
          props.fail &&
          <SuccessfullPopup isError={true} />
        }
      </div>
    </div>
  )
}


export default General;